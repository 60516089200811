import React, { createContext, useReducer } from "react";

export {
  CartProvider,
  decreaseItemQuantity,
  increaseItemQuantity,
  setItemQuantity,
  useCart,
};

const CartStateContext = createContext();
const CartDispatchContext = createContext();

function CartProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    items: {},
  });

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
}

function decreaseItemQuantity({ dispatch, id }) {
  dispatch({ type: "DECREASE_ITEM_QUANTITY", payload: { id } });
}

function increaseItemQuantity({ dispatch, id }) {
  dispatch({ type: "INCREASE_ITEM_QUANTITY", payload: { id } });
}

function setItemQuantity({ dispatch, id, quantity }) {
  dispatch({ type: "SET_ITEM_QUANTITY", payload: { id, quantity } });
}

function useCart() {
  return [useCartState(), useCartDispatch()];
}

function reducer(state, action) {
  switch (action.type) {
    case "DECREASE_ITEM_QUANTITY": {
      const id = action.payload?.id;
      if (id === undefined) {
        throw new Error(
          `invalid cart action, missing id in payload: ${JSON.stringify(
            action,
            null,
            2
          )}`
        );
      }
      if (typeof state.items[id] !== "number") {
        throw new Error(
          `invalid cart item quantity type: ${state.items[id]} for id: ${id}`
        );
      }
      if (state.items[id] <= 0) {
        throw new Error(
          `can't DECREASE_ITEM_QUANTITY cart item quantity below 0`
        );
      }
      if (state.items[id] === 1) {
        const items = { ...state.items };
        delete items[id];
        return {
          ...state,
          items,
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: state.items[id] - 1,
        },
      };
    }
    case "INCREASE_ITEM_QUANTITY": {
      const id = action.payload?.id;
      if (id === undefined) {
        throw new Error(
          `invalid cart action, missing id in payload: ${JSON.stringify(
            action,
            null,
            2
          )}`
        );
      }
      if (state.items[id] === undefined) {
        return {
          ...state,
          items: {
            ...state.items,
            [id]: 1,
          },
        };
      }
      if (typeof state.items[id] !== "number") {
        throw new Error(
          `invalid cart item quantity type: ${state.items[id]} for id: ${id}`
        );
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: state.items[id] + 1,
        },
      };
    }
    case "SET_ITEM_QUANTITY":
      const id = action.payload?.id;
      const quantity = action.payload?.quantity;
      if (typeof quantity !== "number") {
        throw new Error(
          `invalid desired item quantity type: ${quantity} for id: ${id}`
        );
      }
      if (isNaN(quantity)) {
        throw new Error(
          `invalid desired item quantity type: ${quantity} for id: ${id}`
        );
      }
      if (quantity < 0) {
        throw new Error(
          `invalid desired item quantity: ${quantity} for id: ${id}`
        );
      }
      if (quantity === 0) {
        const items = { ...state.items };
        delete items[id];
        return {
          ...state,
          items,
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: quantity,
        },
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useCartState() {
  const context = React.useContext(CartStateContext);
  if (context === undefined) {
    throw new Error("useCartState must be used within a CartProvider");
  }
  return context;
}

function useCartDispatch() {
  const context = React.useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error("useCartDispatch must be used within a CartProvider");
  }
  return context;
}

// export { CartProvider, decreaseItemQuantity, increaseItemQuantity, useCart };
