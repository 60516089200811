import { useParams } from "react-router-dom";
import useMobileDetect from "use-mobile-detect-hook";

import Dish from "./Dish.js";

export default function Section({ menu }) {
  const detectMobile = useMobileDetect();
  const desktop = detectMobile.isDesktop();
  let { section } = useParams();
  section = decodeURI(section);
  const itemID = Object.keys(menu).find((id) => menu[id].gname === section);
  const cname = menu[itemID].gcname;
  const note = menu[itemID].gnote;

  return (
    <div
      style={{
        boxSizing: "border-box",
        overflowY: "auto",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2 style={{ margin: 0, padding: 16 }}>
        {section} ({cname})
      </h2>
      {note && <p style={{ margin: 0, padding: 16, paddingTop: 0 }}>{note}</p>}
      <ul
        style={{
          flex: 1,
          margin: 0,
          marginTop: -16,
          padding: 0,
          paddingBottom: 92,
          listStyle: "none",
        }}
      >
        {Object.keys(menu)
          .filter((id) => menu[id].gname === section)
          .map((id, i) => (
            <Dish key={i} {...menu[id]} />
          ))}
      </ul>
    </div>
  );
}
