import {
  // useHistory,
  useLocation,
  Link,
} from "react-router-dom";

import { useCart } from "./CartContext.js";
import ShoppingCartIcon from "./ShoppingCartIcon.js";

export default function FAB() {
  let location = useLocation();
  // let history = useHistory();
  const [cart] = useCart();

  if (location.pathname === "/cart") {
    return <div></div>;
  }
  const quantity = Object.keys(cart.items).reduce(
    (acc, cur) => cart.items[cur] + acc,
    0
  );

  return (
    <Link to="/cart">
      <div
        style={{
          position: "fixed",
          right: 16,
          bottom: 16,
          zIndex: 100,
          borderRadius: "50%",
          padding: 16,
          backgroundColor: "green",
        }}
      >
        <ShoppingCartIcon size={40} color="white" />
        <div
          style={{
            position: "fixed",
            bottom: 52,
            right: quantity > 9 ? 42 : 48,
            color: "green",
          }}
        >
          {quantity}
        </div>
      </div>
    </Link>
  );
}
