import useMobileDetect from "use-mobile-detect-hook";

import {
  decreaseItemQuantity,
  increaseItemQuantity,
  setItemQuantity,
  useCart,
} from "./CartContext.js";

export default function Dish({
  icname: cname,
  description,
  iino: id,
  iname: name,
  pictures,
  iprice: price,
  iunit: size,
  ispicy: spicy,
}) {
  const detectMobile = useMobileDetect();
  const desktop = detectMobile.isDesktop();
  const [cart, cartDispatch] = useCart();
  const quantity = cart.items?.[id];

  return (
    <li
      style={{
        boxSizing: "border-box",
        maxWidth: desktop ? 480 : undefined,
        margin: 16,
        padding: 16,
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <img
        src={
          pictures
            ? pictures[0]
            : "https://upload.wikimedia.org/wikipedia/commons/f/fd/CookbookEggrollTongs.jpg"
        }
        style={{
          boxSizing: "border-box",
          objectFit: "cover",
          height: 64,
        }}
      />
      <div
        style={{
          flex: 1,
          marginLeft: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <div>
            <div>{name}</div>
            <div>{cname}</div>
          </div>
          <span>${price.toFixed(2)}</span>
        </div>
        <div
          style={{
            boxSizing: "border-box",
            // marginTop: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {typeof quantity === "number" ? (
            <button
              onClick={() => {
                decreaseItemQuantity({ dispatch: cartDispatch, id });
              }}
              style={{
                //
                boxSizing: "border-box",
                height: 24,
                width: 24,
                marginRight: 16,
                border: "1px solid black",
                backgroundColor: "white",
              }}
            >
              -
            </button>
          ) : (
            <span />
          )}
          <span
            onClick={() => {
              const newQuantity = Number(
                window.prompt(`How many ${name} would you like?`, quantity)
              );
              setItemQuantity({
                dispatch: cartDispatch,
                id,
                quantity: newQuantity,
              });
            }}
          >
            {quantity}
          </span>
          <button
            onClick={() => {
              increaseItemQuantity({ dispatch: cartDispatch, id });
            }}
            style={{
              //
              boxSizing: "border-box",
              height: 24,
              width: 24,
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            +
          </button>
        </div>
      </div>
    </li>
  );
}
