// import { Link } from "react-router-dom";

export default function Menu() {
  return (
    <div style={{ flex: 1, overflowY: "auto" }}>
      <div>
        <h2>Menu</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Facilisi
          morbi tempus iaculis urna id. Dui vivamus arcu felis bibendum ut
          tristique et egestas. Adipiscing elit pellentesque habitant morbi
          tristique senectus et netus et. Nulla facilisi morbi tempus iaculis
          urna id volutpat lacus laoreet. Pellentesque eu tincidunt tortor
          aliquam. Dui faucibus in ornare quam. Bibendum arcu vitae elementum
          curabitur vitae nunc. Donec et odio pellentesque diam volutpat commodo
          sed egestas egestas. At quis risus sed vulputate odio ut enim.
        </p>
        <p>
          Venenatis lectus magna fringilla urna. Quis lectus nulla at volutpat
          diam ut venenatis tellus in. Enim facilisis gravida neque convallis.
          Nisl nisi scelerisque eu ultrices vitae auctor eu. Lectus mauris
          ultrices eros in cursus. Dictum non consectetur a erat. Risus feugiat
          in ante metus dictum. Sit amet consectetur adipiscing elit
          pellentesque habitant morbi. Commodo sed egestas egestas fringilla
          phasellus. Diam quis enim lobortis scelerisque fermentum dui. Ultrices
          mi tempus imperdiet nulla. Purus ut faucibus pulvinar elementum
          integer enim. Mattis enim ut tellus elementum sagittis vitae et leo.
          Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Proin nibh
          nisl condimentum id venenatis. Massa tincidunt nunc pulvinar sapien et
          ligula ullamcorper malesuada. Feugiat nisl pretium fusce id velit ut
          tortor pretium. Placerat in egestas erat imperdiet sed euismod nisi
          porta lorem. Aliquam nulla facilisi cras fermentum odio eu. Vitae
          suscipit tellus mauris a diam.
        </p>
        <p>
          Diam sollicitudin tempor id eu. Erat nam at lectus urna duis convallis
          convallis. Praesent tristique magna sit amet. Nisi scelerisque eu
          ultrices vitae auctor eu augue ut lectus. Vitae aliquet nec
          ullamcorper sit amet. Dictumst vestibulum rhoncus est pellentesque.
          Donec ultrices tincidunt arcu non sodales neque. Sit amet dictum sit
          amet justo donec enim. Mattis pellentesque id nibh tortor id aliquet
          lectus. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam
          purus. Dui accumsan sit amet nulla facilisi.
        </p>
        <p>
          Odio euismod lacinia at quis risus sed. Fames ac turpis egestas sed
          tempus urna et pharetra. Malesuada fames ac turpis egestas sed.
          Vivamus arcu felis bibendum ut tristique et egestas quis ipsum. Sed
          risus ultricies tristique nulla aliquet. Bibendum est ultricies
          integer quis auctor elit. Sed tempus urna et pharetra. Egestas purus
          viverra accumsan in nisl nisi scelerisque. Ut etiam sit amet nisl.
          Nisl nisi scelerisque eu ultrices vitae. Urna molestie at elementum eu
          facilisis sed odio. Orci sagittis eu volutpat odio facilisis mauris
          sit amet. Dignissim enim sit amet venenatis urna cursus eget nunc
          scelerisque.
        </p>
        <p>
          Nibh mauris cursus mattis molestie a iaculis at. Leo duis ut diam quam
          nulla porttitor massa id. Tincidunt dui ut ornare lectus sit. Ac ut
          consequat semper viverra nam. At urna condimentum mattis pellentesque
          id nibh tortor id. Justo laoreet sit amet cursus sit amet. Diam
          volutpat commodo sed egestas egestas. Ut enim blandit volutpat
          maecenas volutpat. Elit scelerisque mauris pellentesque pulvinar
          pellentesque habitant. Vulputate sapien nec sagittis aliquam. Sit amet
          consectetur adipiscing elit pellentesque habitant morbi tristique.
          Nisl tincidunt eget nullam non nisi est sit. Nisl nunc mi ipsum
          faucibus vitae aliquet nec ullamcorper sit. Tristique senectus et
          netus et malesuada fames. Velit aliquet sagittis id consectetur purus
          ut faucibus pulvinar. Ultricies leo integer malesuada nunc vel risus
          commodo viverra maecenas. Nunc non blandit massa enim nec. Consequat
          id porta nibh venenatis cras sed felis eget velit. Lacinia quis vel
          eros donec ac odio tempor. Condimentum vitae sapien pellentesque
          habitant morbi tristique senectus.
        </p>
      </div>
    </div>
  );
}
