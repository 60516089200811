// import { useContext } from "react";
// import {
//   Link,
// useHistory
// } from "react-router-dom";

import { useCart } from "./CartContext.js";

export default function Cart() {
  // let history = useHistory();
  const [cart, cartDispatch] = useCart();

  function onClick() {}

  return (
    <div>
      <div>{JSON.stringify(cart, null, 2)}</div>
      <button onClick={onClick}>Next</button>
    </div>
  );
}
