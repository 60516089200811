import {
  Link,
  // useLocation,
  // useRouteMatch,
  // useParams,
} from "react-router-dom";
import useMobileDetect from "use-mobile-detect-hook";

export default function Order({ menu }) {
  const sections = Object.keys(menu).reduce((acc, cur) => {
    if (acc.some((ele) => ele.name === menu[cur].gname)) {
      return acc;
    }
    return [
      ...acc,
      {
        id: menu[cur].ggid,
        name: menu[cur].gname,
        cname: menu[cur].gcname,
        note: menu[cur].gnote,
        aid: menu[cur].gaid,
      },
    ];
  }, []);
  const detectMobile = useMobileDetect();
  const desktop = detectMobile.isDesktop();
  // const desktop = false;

  return (
    <div
      style={{
        boxSizing: "border-box",
        overflowY: "auto",
        flex: 1,
        paddingBottom: sections.length % 2 === 0 ? 108 : 0,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      {sections.map((section, i) => (
        <Link
          key={i}
          to={`/${encodeURI(section.name)}`}
          style={{
            boxSizing: "border-box",
            height: desktop ? 320 : 160,
            width: desktop ? 320 : 160,
            marginTop: 16,
            padding: 16,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            color: "black",
            textDecoration: "none",
            backgroundColor: "white",
          }}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/f/fd/CookbookEggrollTongs.jpg"
            style={{
              boxSizing: "border-box",
              objectFit: "cover",
              width: "100%",
            }}
          />
          <span
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflowX: desktop ? "auto" : "scroll",
            }}
          >
            {section.name} - {section.cname}
          </span>
        </Link>
      ))}
    </div>
  );
}
